import localforage from 'localforage';
import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import HeliosClient from '../context/Helios';

/**
 * Verifies if the inspection link is active or inactive.
 * @param props
 */
const ActiveGuard = (props: { children: JSX.Element; blank: boolean }): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { location } = useHistory();
	const [component, setComponent] = useState(props.children);
	const [isNewComponent, setIsNewComponent] = useState(false);

	const handleIsActive = (isActive: boolean): void => {
		const inactivePath = '/' + id + '/inactive';
		if (!isActive && !location?.pathname?.includes('inactive')) setComponent(<Redirect to={inactivePath} />);
		setIsNewComponent(true);
	};

	const checkIfActive = (): void => {
		(async (): Promise<void> => {
			const inspectionId = id || (await localforage.getItem('inspectionId')) || '';
			try {
				const result = await new HeliosClient().isActive(inspectionId);
				handleIsActive(result);
			} catch (error) {
				handleIsActive(true);
			}
		})();
	};

	useEffect(checkIfActive, []);

	useEffect(() => {
		setComponent(props.children);
	}, [props]);

	return !props.blank || isNewComponent ? component : <></>;
};

/**
 *
 * @param component Component to render
 * @param renderBlank Decides wheter to render component normally or render a blank screen while making validations.
 */
const activeGuard = (component: JSX.Element, renderBlank = false): JSX.Element => (
	<ActiveGuard blank={renderBlank}>{component}</ActiveGuard>
);

export default activeGuard;
