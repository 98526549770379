import React, { useState, useContext } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import clsx from 'clsx';
import Header from '../../../components/common/Header';
import ProgressBar from '../../../components/ProgressBar';
import ScenePhotoOptions from '../../../components/scene/scene-photo-options';
import { ChunkArray } from '../../../shared/utils/array-helpers';
import { useHistory, useParams } from 'react-router';
import { Context } from '../../../context/Context';
import { AppContext, ActionType, Brands } from '../../../context/interfaces';
import { BRAND } from 'shared/utils';

import './styles.scss';
import { ScenePhoto } from '../../../types/scene-photo';
interface Props {
	photos: Array<ScenePhoto>;
	screenIndex: number;
}

const ScenePhotosScreen = (props: Props): JSX.Element => {
	const [selectedScenePhoto, setSelectedScenePhoto] = useState<string>('');

	const scenesOptionsRows = ChunkArray([...props.photos], 2);
	const context = useContext(Context) as AppContext;
	const { dispatch } = context;
	const { id } = useParams<{ id: string }>();

	const history = useHistory();

	const handleConfirm = (): void => {
		if (selectedScenePhoto) {
			const photoObj = props.photos.find((photo: ScenePhoto) => photo.value === selectedScenePhoto) as ScenePhoto;
			dispatch({
				type: ActionType.SET_SCENE_PHOTO,
				data: photoObj,
			});
			if (BRAND === Brands.CSM) {
				history.push('/' + id + '/injured/sketch');
			} else {
				history.push('/' + id + '/insured/sketch');
			}
		}
	};

	return (
		<div className="sceneScreen">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />

			<Header title="Selecciona un escenario, lo más parecido al lugar del accidente." subtitle="" />

			<div className="sceneScreen__options">
				<ScenePhotoOptions
					selectedScene={selectedScenePhoto}
					scenesPhotosRows={scenesOptionsRows}
					onClickScene={(value) => setSelectedScenePhoto(value)}
				/>
			</div>

			<button
				className={clsx('confirm-button', { disabled: !selectedScenePhoto })}
				disabled={!selectedScenePhoto}
				onClick={handleConfirm}
			>
				Confirmar
			</button>
		</div>
	);
};

export default ScenePhotosScreen;
