import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmButton from 'components/common/ConfirmButton';
import React, { FC, useContext, useState } from 'react';
import 'assets/styles/screens/survey/Survey.scss';
import ProgressBar from 'components/ProgressBar';
import Rating from '@material-ui/lab/Rating';

import { ReactComponent as Survey99 } from 'assets/icons/99_VI.svg';
import { ReactComponent as SurveyMarker } from 'assets/icons/marker_VI.svg';
import { Context } from 'context/Context';
import { AppContext } from 'context/interfaces';
import { uploadSurvey } from 'context/Utils';
import { SurveyPayload } from 'types/survey';
import { finishVirtualInspectorGTM } from 'shared/utils/gooogleTagManager';
import HeliosClient from 'context/Helios';
import { useLocation, useParams } from 'react-router';
import { urlIdRewrite } from 'shared/utils';

interface SurveyProps {
	screenKey: any;
	to: string;
}

const Survey: FC<SurveyProps> = ({ screenKey, to }: SurveyProps) => {
	const [recommend, setRecommend] = useState<number | null>(null);
	const [experience, setExperience] = useState<number | null>(null);
	const [comment, setComment] = useState<string>('');
	const context = useContext(Context) as AppContext;
	const locationPath = useLocation();
	const { id } = useParams<{ id: string }>();

	const handleSubmit = async (): Promise<void> => {
		if (context.eventId) {
			const body: SurveyPayload = {
				inspectionId: context.eventId,
				comment,
				recommend: Number(recommend),
				experience: Number(experience),
			};
			// Validate if user filled some value
			if (body.experience > 0 || body.recommend > 0 || comment.trim().length > 0) {
				await uploadSurvey(body);
			}

			submitVI();
		}
	};

	const submitVI = (): void => {
		finishVirtualInspectorGTM(context, locationPath.pathname);
		new HeliosClient().finishInspection(context, id);
	};

	return (
		<div className="survey-screen">
			<div className="survey-screen__header">
				<Breadcrumbs currentStep={screenKey} />
				<ProgressBar screenIndex={screenKey} />
				<h2>Cuéntanos tu experiencia</h2>

				<hr className="rate-divider" />
				<div className="rate-examples">
					<div className="rate-example">
						<Rating value={1} readOnly size="large" />
						<span className="rate-label">= Pobre</span>
					</div>
					<div className="rate-example">
						<Rating value={5} readOnly size="large" />
						<span className="rate-label">= Excelente</span>
					</div>
				</div>
				<hr className="rate-divider" />
			</div>
			<div className="survey-container">
				<form className="questions" onSubmit={handleSubmit}>
					<div className="question">
						<div className="question__rate">
							<p className="question__text">¿Cómo calificarías tu experiencia con esta aplicación?</p>
							<Rating
								name="experience"
								value={experience}
								size="large"
								onChange={(event: React.ChangeEvent<{}>, newValue: number | null) => {
									setExperience(newValue);
								}}
							/>
						</div>
						{/* <img src={survey99} alt="heart" className="filled-svg" /> */}
						<div className="question__image">
							<SurveyMarker />
						</div>
					</div>
					<hr className="section-divider" />
					<div className="question">
						<div className="question__rate">
							<p className="question__text">¿Cuán probable es que nos recomiende a un amigo o familiar?</p>
							<Rating
								name="recommend"
								value={recommend}
								size="large"
								onChange={(event: React.ChangeEvent<{}>, newValue: number | null) => {
									setRecommend(newValue);
								}}
							/>
						</div>
						<div className="question__image">
							<Survey99 />
						</div>
					</div>
					<hr className="section-divider" />
					<div className="question--comment">
						<p className="question__text">Comentarios adicionales:</p>
						<textarea rows={5} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
					</div>
				</form>
			</div>
			<ConfirmButton text="Finalizar" to={urlIdRewrite(to, id)} onClick={handleSubmit} />
		</div>
	);
};

export default Survey;
