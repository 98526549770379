import { Photo, PhotoSection, Validation, PhotoSections } from '../../../context/interfaces';
import { ReactComponent as DamageIcon } from 'assets/icons/damage.svg';
import { ReactComponent as CarFrontSide } from 'assets/icons/car_front.svg';
import { ReactComponent as InternalPhoto } from 'assets/icons/internal-photo.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/i-360video.svg';
import { panoramic, other, carBack, damage, license, registroUnico, cedula, closeUp } from '../../../context/Utils';
import { PhotoId } from '@connect-technology/connect-react-components-lib';

export const suraPhotos: Photo[] = [
	{
		id: PhotoId.PANORAMIC,
		section: PhotoSection.PANORAMIC,
		placeholder: panoramic,
		selected: true,
		description: 'Foto Panorámica',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.INSURED_VEHICLE,
		section: PhotoSection.PANORAMIC,
		placeholder: closeUp,
		selected: false,
		description: 'Foto Panorámica de Accidente Vehículo Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.INSURED_VEHICLE_BACK,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: carBack,
		selected: false,
		description: 'Foto Trasera del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE, Validation.EXTRACT_PLATE],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_1,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: false,
		description: 'Foto de Daños 1 del Vehículo de Asegurado',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_2,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: false,
		description: 'Foto de Daños 2 del Vehículo de Asegurado',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.INSURED_VEHICLE_DAMAGE_3,
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: damage,
		selected: false,
		description: 'Foto de Daños 3 del Vehículo de Asegurado',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.INSURED_LICENSE_OR_CEDULA_PA,
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: license,
		selected: false,
		description: 'Foto de Licencia o Cédula del Asegurado',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'insured',
	},
	{
		id: PhotoId.COUNTERPART_VEHICLE, // TODO:
		section: PhotoSection.COUNTERPARTS,
		placeholder: closeUp,
		selected: true,
		description: 'Foto Panorámica de Accidente Vehículo Contraparte',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_VEHICLE_BACK,
		section: PhotoSection.COUNTERPARTS,
		placeholder: carBack,
		selected: false,
		description: 'Foto Trasera del Vehículo de Contraparte',
		required: true,
		validations: [Validation.HAS_VEHICLE, Validation.EXTRACT_PLATE],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_VEHICLE_DAMAGE_1,
		section: PhotoSection.COUNTERPARTS,
		placeholder: damage,
		selected: false,
		description: 'Foto Daños 1 del Vehículo de Contraparte',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_VEHICLE_DAMAGE_2,
		section: PhotoSection.COUNTERPARTS,
		placeholder: damage,
		selected: false,
		description: 'Foto Daños 2 del Vehículo de Contraparte',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_VEHICLE_DAMAGE_3,
		section: PhotoSection.COUNTERPARTS,
		placeholder: damage,
		selected: false,
		description: 'Foto Daños 3 del Vehículo de Contraparte',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_LICENSE_PA,
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: license,
		selected: false,
		description: 'Foto de Licencia de la Contraparte',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_REGISTRO_UNICO,
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: registroUnico,
		selected: false,
		description: 'Foto de Registro Único Vehícular de la Contraparte',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_CEDULA,
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: cedula,
		selected: false,
		description: 'Foto de Cédula de la Contraparte',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.COUNTERPART_POLICY,
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: license,
		selected: false,
		description: 'Foto de Póliza de la Contraparte',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'counterpart',
	},
	{
		id: PhotoId.OTHERS_1,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: true,
		description: 'Otros 1',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	},
	{
		id: PhotoId.OTHERS_2,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: false,
		description: 'Otros 2',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	},
	{
		id: 'vehicle_video',
		section: PhotoSection.VEHICLE_VIDEO,
		selected: false,
		description: 'Video',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'video',
	},
];

export const suraPhotoSections: PhotoSections[] = [
	{
		description: 'Asegurado',
		srcImage: CarFrontSide,
		sectionId: 'insured',
		photosIds: [
			PhotoId.PANORAMIC,
			PhotoId.INSURED_VEHICLE,
			PhotoId.INSURED_VEHICLE_BACK,
			PhotoId.INSURED_VEHICLE_DAMAGE_1,
			PhotoId.INSURED_VEHICLE_DAMAGE_2,
			PhotoId.INSURED_VEHICLE_DAMAGE_3,
			PhotoId.INSURED_LICENSE_OR_CEDULA_PA,
		],
		required: true,
	},
	{
		description: 'Contraparte',
		srcImage: DamageIcon,
		sectionId: 'counterpart',
		photosIds: [
			PhotoId.COUNTERPART_VEHICLE,
			PhotoId.COUNTERPART_VEHICLE_BACK,
			PhotoId.COUNTERPART_VEHICLE_DAMAGE_1,
			PhotoId.COUNTERPART_VEHICLE_DAMAGE_2,
			PhotoId.COUNTERPART_VEHICLE_DAMAGE_3,
			PhotoId.COUNTERPART_LICENSE_PA,
			PhotoId.COUNTERPART_REGISTRO_UNICO,
			PhotoId.COUNTERPART_CEDULA,
			PhotoId.COUNTERPART_POLICY,
		],
		required: true,
	},
	{
		description: 'Otros',
		srcImage: InternalPhoto,
		sectionId: 'others',
		photosIds: [PhotoId.OTHERS_1, PhotoId.OTHERS_2],
		required: false,
	},
	{
		description: 'Video',
		srcImage: VideoIcon,
		sectionId: 'video',
		photosIds: [],
		required: false,
	},
];
