import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import '../../assets/styles/components/common/ConfirmButton.scss';

interface Props {
	to: string;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	text?: string;
}

export default function ConfirmButton(props: Props): JSX.Element {
	const disabled = props.disabled || false;

	const className = cn('confirm-button', { disabled });
	const onClick = disabled
		? (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => event.preventDefault()
		: props.onClick;
	return (
		<Link className={className} to={props.to} onClick={onClick}>
			{props.text ? props.text : 'Confirmar'}
		</Link>
	);
}
