import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import { SurveyPayload } from 'types/survey';

const CONNECT_API = process.env.REACT_APP_CONNECT_API;
export const AXIOS_CONFIG = {
	headers: {
		'Content-Type': 'application/json',
	},
};

export default class ConnectServicesClient {
	constructor(private axios: AxiosStatic = Axios) {}

	async uploadImage(eventId: number, data: any): Promise<AxiosResponse> {
		return await this.axios.post(`${CONNECT_API}/api/v1/images/${eventId}/bucket`, JSON.stringify(data), AXIOS_CONFIG);
	}

	async deleteImage(eventId: number, data: any): Promise<AxiosResponse> {
		return await this.axios.delete(`${CONNECT_API}/api/v1/images/${eventId}/bucket`, { data: data });
	}

	async getLocalization(countryCode: string): Promise<AxiosResponse> {
		return await this.axios.get(`${CONNECT_API}/api/v1/catalogs/provinces?code=${countryCode}`);
	}

	async submitSurvey(data: SurveyPayload): Promise<AxiosResponse> {
		return await this.axios.post(`${CONNECT_API}/api/v1/survey`, data, AXIOS_CONFIG);
	}

	async uploadMedia(
		serviceNumber: string,
		filename: string,
		fileExtension: string,
		file: File
	): Promise<AxiosResponse> {
		const formData = new FormData();
		formData.append('FolderRoot', serviceNumber);
		formData.append('FileDataStream', file);
		formData.append('FileName', filename);
		formData.append('FileExtension', fileExtension);

		return await this.axios.post(`${CONNECT_API}/api/v1/media`, formData, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		});
	}

	async deleteMediaFile(url: string): Promise<AxiosResponse> {
		return await this.axios.post(`${CONNECT_API}/api/v1/media/remove`, {
			fileURL: url,
		});
	}
}
