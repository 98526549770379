import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { BRAND, urlIdRewrite } from 'shared/utils';
import { ReactComponent as CheckmarkSVG } from '../../assets/icons/checkmark_overview.svg';
import '../../assets/styles/screens/damages/Damages.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import ProgressBar from '../../components/ProgressBar';
import VehicleDamages from '../../components/vehicle/Damages';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { AppContext, Brands, IndividualStateKey, ScreenIndex, ScreenIndexCSM } from '../../context/interfaces';
import { checkmarkOverview } from '../../context/Utils';
import { damageScreenGTM } from '../../shared/utils/gooogleTagManager';

interface Props {
	title: string;
	screenKey: number;
	to: string; // link to which continue
}

const DamagesScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const location = useLocation();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const [showMessageWindow, setShowMessageWindow] = useState(false);

	const currentStateKey = history.location.pathname.split('/')[2] as IndividualStateKey;
	const canContinue = Object.keys(ctx[currentStateKey].damages).length > 0;
	const screenIndex =
		currentStateKey === IndividualStateKey.COUNTERPART
			? ScreenIndex.COUNTERPART_DAMAGES
			: ScreenIndexCSM.INSURED_DAMAGES;

	const onConfirmClick = (): void => {
		new HeliosClient().saveInspection(ctx, id);
		if ((BRAND === Brands.LAFISE || BRAND === Brands.OCEANICA) && screenIndex === ScreenIndex.COUNTERPART_DAMAGES) {
			history.push(urlIdRewrite(props.to, id));
		} else {
			setShowMessageWindow(true);
		}
	};

	const onDismissClick = (): void => {
		damageScreenGTM(ctx, location.pathname);
		history.push(urlIdRewrite(props.to, id));
	};

	return (
		<div className="damages-screen">
			<div className="container">
				<Breadcrumbs currentStep={props.screenKey} />
				<ProgressBar screenIndex={props.screenKey} />
				<Header title="Selecciona todas las áreas afectadas del vehículo" />
				<VehicleDamages screenKey={props.screenKey} />
			</div>
			<button className="confirm-btn" disabled={!canContinue} onClick={onConfirmClick}>
				Confirmar
			</button>
			{showMessageWindow && (
				<div className="completed">
					<div className="completed__container">
						{BRAND.toLowerCase() === Brands.ASSA ? (
							<CheckmarkSVG className="checkmark" />
						) : (
							<img src={checkmarkOverview} className="checkmark" alt="" />
						)}
						{(currentStateKey === IndividualStateKey.INSURED || currentStateKey === IndividualStateKey.INJURED) && (
							<h1>¡Bien hecho!</h1>
						)}
						{currentStateKey === IndividualStateKey.COUNTERPART && <h1>¡Super!</h1>}
						<div className="message">
							{BRAND !== Brands.LAFISE &&
								BRAND !== Brands.OCEANICA &&
								!Brands.CONNECT &&
								currentStateKey === IndividualStateKey.INSURED && (
									<>
										<p>Su información de los daños ha sido completada.</p>
										<p>Sus datos han sido completados, pasemos a ingresar datos de la contraparte.</p>
									</>
								)}
							{(BRAND === Brands.LAFISE || BRAND === Brands.OCEANICA || Brands.CONNECT) &&
								currentStateKey === IndividualStateKey.INSURED && (
									<p>
										Datos de Asegurado Completados.
										<br />
										<br />
										Información de Conductor y Asegurado ha sido guardada.
										<br />
										<br />
										Presione la pantalla para llenar datos de la contraparte.
									</p>
								)}

							{BRAND !== Brands.LAFISE &&
								BRAND !== Brands.OCEANICA &&
								currentStateKey === IndividualStateKey.COUNTERPART && (
									<p>
										Los datos de {props.title}
										<br /> han sido completados.
										<br />
										<br />
										Pasemos a tomar las fotos del accidente.
									</p>
								)}

							{currentStateKey === IndividualStateKey.INJURED && <p>Su información de los daños ha sido completada.</p>}
						</div>
					</div>
					<ConfirmButton to={urlIdRewrite(props.to, id)} onClick={onDismissClick} />
				</div>
			)}
		</div>
	);
};

export default DamagesScreen;
