import {
	DispatchFunction,
	ActionType,
	IndividualStateKey,
	IndividualState,
	FieldStateKey,
	Brands,
	ScreenIndex,
	SelectedData,
} from '../../context/interfaces';
import { BRAND } from 'shared/utils';

const selectedSexOptions = [
	{ id: '', name: null },
	{ id: '1', name: 'Femenino' },
	{ id: '2', name: 'Masculino' },
	{ id: '-1', name: 'N / D' },
];

// Taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const validateEmail = (email: string): boolean => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase().trim());
};

export const isNum = (value: string): boolean => {
	return /^\d+$/.test(value);
};

export const buildFieldOnChange = (
	dispatch: DispatchFunction,
	stateKey: IndividualStateKey,
	fieldStateKey: FieldStateKey
) => {
	return ({ target }: any): void => {
		const value: any = target.type === 'checkbox' ? target.checked : target.value;
		dispatch({
			type: ActionType.SET_FIELD_VALUE,
			data: { stateKey, fieldStateKey, value },
		});
	};
};

export interface Field {
	fieldKey: FieldStateKey;
	label: string;
	type: string;
	inputMode?: any;
	inputSize?: number;
	readonly: boolean;
	required: boolean;
	stateSelector: (state: IndividualState) => any;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isValid: (value: string) => boolean; // this is orthogonal to 'required'
	errorMessage: string;
	selectedData?: SelectedData[];
}

export const assignDataToForm = (dispatch: DispatchFunction, screenKey: ScreenIndex, formData: IndividualState) => {
	if (screenKey === ScreenIndex.DRIVER) {
		const dataDriver = [
			{ fsKey: FieldStateKey.DRIVER_NAME.toString(), value: formData.fields.firstName },
			{ fsKey: FieldStateKey.DRIVER_LASTNAME.toString(), value: formData.fields.lastName },
			{ fsKey: FieldStateKey.DRIVER_SEX.toString(), value: formData.fields.sex },
			{ fsKey: FieldStateKey.DRIVER_LICENSE.toString(), value: formData.fields.license },
			{ fsKey: FieldStateKey.DRIVER_URBANIZATION.toString(), value: formData.fields.urbanization },
			{ fsKey: FieldStateKey.DRIVER_POSTALCODE.toString(), value: formData.fields.postalCode },
			{ fsKey: FieldStateKey.DRIVER_PHONE.toString(), value: formData.fields.phone },
			{ fsKey: FieldStateKey.DRIVER_EMAIL.toString(), value: formData.fields.email },
		];
		dataDriver.map((m) => {
			switch (BRAND) {
				case Brands.CSM:
					dispatch({
						type: ActionType.SET_FIELD_VALUE,
						data: { stateKey: IndividualStateKey.INJURED, fieldStateKey: m.fsKey, value: m.value },
					});
					break;
				default:
					dispatch({
						type: ActionType.SET_FIELD_VALUE,
						data: { stateKey: IndividualStateKey.INSURED, fieldStateKey: m.fsKey, value: m.value },
					});
					break;
			}
			return true;
		});
	}
};

/**
 * This function we expect to handle the dynamic calculation for what fields
 * to show given :individual, but in the future could be based on the company,
 * country, etc...
 * @param dispatch
 */
export const buildFields = (
	dispatch: DispatchFunction,
	stateKey: IndividualStateKey,
	screenKey: ScreenIndex
): Field[] => {
	const name = {
		fieldKey: FieldStateKey.NAME,
		label: 'Nombre y Apellidos',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.name,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.NAME),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Nombre y Apellidos requerido',
	};
	const firstName = {
		fieldKey: FieldStateKey.FIRST_NAME,
		label: 'Nombre',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.firstName,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.FIRST_NAME),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Nombre requerido',
	};
	const lastName = {
		fieldKey: FieldStateKey.LAST_NAME,
		label: 'Apellidos',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.lastName,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.LAST_NAME),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Apellidos requeridos',
	};
	const phone = {
		fieldKey: FieldStateKey.PHONE,
		label: 'Teléfono',
		type: 'phone',
		inputMode: 'numeric',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.phone,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.PHONE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Teléfono requerido',
	};
	const plate = {
		fieldKey: FieldStateKey.PLATE,
		label: 'Placa',
		type: 'text',
		readonly: stateKey === IndividualStateKey.INSURED,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.plate,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.PLATE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Placa requerida',
	};
	const make = {
		fieldKey: FieldStateKey.MAKE,
		label: 'Marca',
		type: 'text',
		readonly: stateKey === IndividualStateKey.INSURED,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.make,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.MAKE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Marca requerida',
	};
	const model = {
		fieldKey: FieldStateKey.MODEL,
		label: 'Modelo',
		type: 'text',
		readonly: stateKey === IndividualStateKey.INSURED,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.model,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.MODEL),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Modelo requerido',
	};
	const baseYear = {
		fieldKey: FieldStateKey.YEAR,
		label: 'Año',
		type: 'number',
		required: true,
		inputMode: 'numeric',
		stateSelector: (state: IndividualState): string => state?.fields?.year,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.YEAR),
		isValid: (value: string): boolean =>
			Boolean(value) && String(value).trim().length === 4 && isNum(String(value).trim()),
		errorMessage: 'Año inválido',
	};
	const baseColor = {
		fieldKey: FieldStateKey.COLOR,
		label: 'Color',
		type: 'text',
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.color,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.COLOR),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Color requerido',
	};
	const email = {
		fieldKey: FieldStateKey.EMAIL,
		label: 'Correo electrónico',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.email,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.EMAIL),
		isValid: (value: string): boolean => validateEmail(value),
		errorMessage: 'Correo electrónico inválido',
	};
	const sex = {
		fieldKey: FieldStateKey.SEX,
		label: 'Género',
		type: 'selected',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.sex,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.SEX),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Género es requerido',
		selectedData: selectedSexOptions,
	};
	const license = {
		fieldKey: FieldStateKey.LICENSE,
		label: 'Número de licencia',
		type: 'text',
		inputSize: 13,
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.license,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.LICENSE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Licencia requerida',
	};
	const postalCode = {
		fieldKey: FieldStateKey.POSTAL_CODE,
		label: 'Código Postal',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.postalCode,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.POSTAL_CODE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Código Postal requerido',
	};
	const urbanization = {
		fieldKey: FieldStateKey.URBANIZATION,
		label: 'Urb, Barrio, Edificio o Apartado Postal',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.urbanization,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.URBANIZATION),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Requerido',
	};
	const isOtherDriver = {
		fieldKey: FieldStateKey.IS_OTHER_DRIVER,
		label: 'Marca el encasillado si el conductor es diferente al asegurado.',
		type: 'checkbox',
		readonly: false,
		required: false,
		stateSelector: (state: IndividualState): string => state?.fields?.isOtherDriver,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.IS_OTHER_DRIVER),
		isValid: (value: string): boolean => true,
		errorMessage: '',
	};

	// Default: INSURED configuration
	const year = {
		...baseYear,
		readonly: true,
	};

	const color = {
		...baseColor,
		readonly: true,
	};

	const driverName = {
		fieldKey: FieldStateKey.DRIVER_NAME,
		label: 'Nombre (Conductor)',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverName,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_NAME),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Nombre requerido',
	};
	const driverLastName = {
		fieldKey: FieldStateKey.DRIVER_LASTNAME,
		label: 'Apellidos (Conductor)',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverLastName,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_LASTNAME),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Apellidos requeridos',
	};
	const driverPhone = {
		fieldKey: FieldStateKey.DRIVER_PHONE,
		label: 'Teléfono (Conductor)',
		type: 'phone',
		inputMode: 'numeric',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverPhone,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_PHONE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Teléfono requerido',
	};
	const driverMarbete = {
		fieldKey: FieldStateKey.DRIVER_MARBETE,
		label: 'Marbete fecha de vencimiento',
		type: 'date',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverMarbete,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_MARBETE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Marbete requerido',
	};
	const driverMileage = {
		fieldKey: FieldStateKey.DRIVER_MILEAGE,
		label: 'Millaje',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverMileage,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_MILEAGE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Millaje requerido',
	};
	const driverEmail = {
		fieldKey: FieldStateKey.DRIVER_EMAIL,
		label: 'Correo electrónico (Conductor)',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverEmail,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_EMAIL),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Correo electrónico inválido',
	};
	const driverRelationship = {
		fieldKey: FieldStateKey.RELATIONSHIP,
		label: 'Parentesco (Conductor)',
		type: 'text',
		readonly: false,
		required: false,
		stateSelector: (state: IndividualState): string => state?.fields?.driverRelationship,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.RELATIONSHIP),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Parentesco inválido',
	};
	const driverSex = {
		fieldKey: FieldStateKey.DRIVER_SEX,
		label: 'Género (Conductor)',
		type: 'selected',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverSex,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_SEX),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Sexo es requerido',
		selectedData: selectedSexOptions,
	};
	const driverLicense = {
		fieldKey: FieldStateKey.DRIVER_LICENSE,
		label: 'Número de licencia (Conductor)',
		type: 'text',
		inputSize: 10,
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverLicense,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_LICENSE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Licencia requerida',
	};
	const driverPostalCode = {
		fieldKey: FieldStateKey.DRIVER_POSTALCODE,
		label: 'Código Postal (Conductor)',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverPostalCode,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_POSTALCODE),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Código Postal requerido',
	};
	const driverUrbanization = {
		fieldKey: FieldStateKey.DRIVER_URBANIZATION,
		label: 'Urb, Barrio, Edificio o Apartado Postal',
		type: 'text',
		readonly: false,
		required: true,
		stateSelector: (state: IndividualState): string => state?.fields?.driverUrbanization,
		onChange: buildFieldOnChange(dispatch, stateKey, FieldStateKey.DRIVER_URBANIZATION),
		isValid: (value: string): boolean => Boolean(value) && String(value).trim().length > 0,
		errorMessage: 'Requerido',
	};

	let fields = [firstName, lastName, phone, year, make, model, color, plate, email];

	switch (stateKey) {
		case IndividualStateKey.INSURED: {
			if ([Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands)) {
				isOtherDriver.label = 'Marcar si el conductor es diferente al asegurado';
			}

			fields = [firstName, lastName, sex, license, phone, email, isOtherDriver];

			switch (screenKey) {
				case ScreenIndex.DRIVER: {
					fields = [driverName, driverLastName, driverSex, driverLicense, driverPhone, driverEmail];
					if ([Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS, Brands.ASSA].includes(BRAND as Brands)) {
						fields = [
							driverName,
							driverLastName,
							driverSex,
							driverLicense,
							driverPhone,
							driverEmail,
							driverRelationship,
						];
					}
					break;
				}
				case ScreenIndex.VEHICLE: {
					fields = [plate, year, make, model, color];

					const readOnlyFields = [plate, year, make, model];
					if ([Brands.SURA, Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands))
						fields.forEach((f) => {
							f.readonly = readOnlyFields.includes(f);
						});
					break;
				}
				default:
					break;
			}
			break;
		}
		case IndividualStateKey.INJURED: {
			fields = [];
			firstName.label = 'Nombre del dueño';
			lastName.label = 'Apellido del dueño';
			isOtherDriver.label = 'Marca el encasillado si el conductor es diferente al asegurado.';
			fields = [firstName, lastName, sex, license, urbanization, postalCode, phone, email, isOtherDriver];

			switch (screenKey) {
				case ScreenIndex.DRIVER: {
					fields = [];
					if (BRAND === Brands.CSM) {
						fields = [
							driverName,
							driverLastName,
							driverSex,
							driverLicense,
							driverUrbanization,
							driverPostalCode,
							driverPhone,
							driverEmail,
						];
					} else {
						fields = [
							driverName,
							driverLastName,
							driverSex,
							driverLicense,
							driverUrbanization,
							driverPostalCode,
							driverPhone,
							driverEmail,
						];
					}
					break;
				}
				case ScreenIndex.VEHICLE: {
					fields = [];
					plate.label = 'Tablilla';
					plate.errorMessage = 'Tablilla es requerida';
					fields = [plate, year, make, model, color];

					if (BRAND === Brands.CSM) {
						fields.push(driverMarbete, driverMileage);
					}

					const readOnlyFields = [plate, year, make, model];
					if (BRAND === Brands.CSM)
						fields.forEach((f) => {
							f.readonly = readOnlyFields.includes(f);
						});
					break;
				}
				default:
					break;
			}
			break;
		}
		case IndividualStateKey.COUNTERPART: {
			year.readonly = false;
			color.readonly = false;
			fields = [name, phone, year, make, model, color, plate, email];

			switch (BRAND) {
				case Brands.SURA: {
					email.required = false;
					break;
				}
				case Brands.LAFISE:
				case Brands.OCEANICA:
				case Brands.QUALITAS: {
					fields.forEach((f) => (f.required = false));
					break;
				}
			}
			break;
		}

		default:
			break;
	}

	return fields;
};
