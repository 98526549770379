import React from 'react';
import { IndividualStateKey, PhotoSection, Brands } from '../../context/interfaces';
import { BRAND } from 'shared/utils';

/* eslint react/prop-types: 0 */
interface Props {
	awsUrlsByCategory: { [key in PhotoSection]: string[] };
	stateKey: string;
}

const SectionDetail = (props: Props): JSX.Element => {
	const photoSection = (title: string, section: PhotoSection, alt: string): JSX.Element => {
		return (
			<>
				<h2>{title}</h2>
				<div id={'section_' + section.toString()} className="img-container">
					{props.awsUrlsByCategory[section].map((awsUrl, index) => {
						return <img key={index} src={awsUrl} alt={alt} />;
					})}
				</div>
			</>
		);
	};

	return (
		<>
			<h1>
				{props.stateKey === IndividualStateKey.INSURED
					? 'Asegurado'
					: props.stateKey === IndividualStateKey.INJURED
					? 'Perjudicado'
					: 'Contraparte'}
			</h1>

			{(BRAND === Brands.CONNECT || BRAND === Brands.SURA) && (
				<>
					{photoSection('Fotos de Auto', PhotoSection.INSURED_VEHICLE, 'insured vehicle')}
					{photoSection('Documentos', PhotoSection.INSURED_DOCUMENTS, 'insured documents')}
					{photoSection('Contraparte', PhotoSection.COUNTERPARTS, 'counterpart vehicle')}
					{photoSection('Panorámica', PhotoSection.PANORAMIC, 'panoramic')}
					{props.awsUrlsByCategory[PhotoSection.COUNTERPARTS_DOCUMENTS] &&
						props.awsUrlsByCategory[PhotoSection.COUNTERPARTS_DOCUMENTS].length > 0 &&
						photoSection('Contraparte Documentos', PhotoSection.COUNTERPARTS_DOCUMENTS, 'counterpart documents')}
					{props.awsUrlsByCategory[PhotoSection.OTHERS] &&
						props.awsUrlsByCategory[PhotoSection.OTHERS].length > 0 &&
						photoSection('Otros', PhotoSection.OTHERS, 'others')}
				</>
			)}

			{BRAND === Brands.CSM && (
				<>
					{props.stateKey === IndividualStateKey.INSURED && (
						<>
							{photoSection('Licencia de Conducir', PhotoSection.INSURED_LICENSE, 'license')}
							{photoSection('Licencia de Vehículo', PhotoSection.INSURED_VEHICLE_LICENSE, 'vehicle license')}
							{photoSection('Odómetro', PhotoSection.INSURED_ODOMETER, 'odometer')}
							{photoSection('Marbete', PhotoSection.INSURED_MARBETE, 'marbete')}
							{photoSection('Chasis (VIN Number)', PhotoSection.INSURED_VIN, 'chassis')}
							{photoSection('Tablilla', PhotoSection.INSURED_TABLILLA, 'tablilla')}
							{photoSection(
								'Vehículo (Laterales, Frontal y Trasera)',
								PhotoSection.INSURED_VEH_PHOTOS,
								'vehicle photos'
							)}
							{photoSection('Daños', PhotoSection.INSURED_VEH_DAMAGE_PHOTOS, 'damage photos')}
							{photoSection('Accidente (Panorámica)', PhotoSection.INSURED_VEH_PANORAMIC, 'panoramic')}
							{photoSection('Otros', PhotoSection.INSURED_OTHERS_DOCS, 'others')}
						</>
					)}
					{props.stateKey === IndividualStateKey.INJURED && (
						<>
							{photoSection('Licencia de Conducir', PhotoSection.INJURED_LICENSE, 'license')}
							{photoSection('Licencia de Vehículo', PhotoSection.INJURED_VEHICLE_LICENSE, 'vehicle license')}
							{photoSection('Odómetro', PhotoSection.INJURED_ODOMETER, 'odometer')}
							{photoSection('Marbete', PhotoSection.INJURED_MARBETE, 'marbete')}
							{photoSection('Chasis (VIN Number)', PhotoSection.INJURED_VIN, 'chassis')}
							{photoSection('Tablilla', PhotoSection.INJURED_TABLILLA, 'tablilla')}
							{photoSection(
								'Vehículo (Laterales, Frontal y Trasera)',
								PhotoSection.INJURED_VEH_PHOTOS,
								'vehicle photos'
							)}
							{photoSection('Daños', PhotoSection.INJURED_VEH_DAMAGE_PHOTOS, 'damage photos')}
							{photoSection('Accidente (Panorámica)', PhotoSection.INJURED_VEH_PANORAMIC, 'panoramic')}
							{photoSection('Otros', PhotoSection.INJURED_OTHERS_DOCS, 'others')}
						</>
					)}
				</>
			)}

			{BRAND === Brands.ASSA && (
				<>
					{photoSection('Fotos de Vehículo', PhotoSection.INSURED_VEHICLE, 'insured vehicle')}
					{photoSection('Documentos', PhotoSection.INSURED_DOCUMENTS, 'insured documents')}
					{props.awsUrlsByCategory[PhotoSection.OTHERS]?.length && photoSection('Otros', PhotoSection.OTHERS, 'others')}
				</>
			)}

			{[Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands) && (
				<>
					{photoSection('Fotos de Vehículo', PhotoSection.INSURED_VEHICLE, 'insured vehicle')}
					{photoSection('Documentos', PhotoSection.INSURED_DOCUMENTS, 'insured documents')}
					{photoSection('Contraparte', PhotoSection.COUNTERPARTS, 'counterpart vehicle')}
					{props.awsUrlsByCategory[PhotoSection.OTHERS].length && photoSection('Otros', PhotoSection.OTHERS, 'others')}
				</>
			)}
		</>
	);
};

export default SectionDetail;
