import React, { useEffect, useRef, useState } from 'react';
import RoundButton from '../RoundButton';
import { getDamageArea } from './Utils';
import '../../assets/styles/components/vehicle/DamageWindow.scss';

interface DamageWindowProps {
	show: boolean;
	areaId: number;
	damageTypes: string[];
	damageList?: string[] | null;
	close: () => void;
	onConfirm: (areaareaId: number, damageTypes: string[]) => void;
}

const DAMAGE_TYPES = ['Rayadura', 'Abolladura', 'Daños mecánicos', 'Quebrado/Rajado'];

export function buildUpdateStateFn(damageTypes: string[], setDamageTypes: any) {
	return (damageType: string): void => {
		const newDamageTypes = [...damageTypes];
		const index = damageTypes.indexOf(damageType);
		if (index === -1) {
			newDamageTypes.push(damageType);
		} else {
			newDamageTypes.splice(index, 1);
		}
		setDamageTypes(newDamageTypes);
	};
}

const DamageWindow = (props: DamageWindowProps): JSX.Element => {
	const classes = `damage-window-container ${!props.show ? 'hidden' : ''}`;
	const windowRef: any = useRef(null);
	// We need internal/disposable UI state, to allow the user to consider
	// options before actually effectuating changes to the global state. That
	// is, the user might make selection changes but then close (in which case)
	// we _do not_ want the changes to have taken place in global state.
	const [internalDamageTypes, setInternalDamageTypes] = useState<string[] | null>(null);

	// If internal-state is set, use it. Else, fallback to selected global state.
	const damageTypes = internalDamageTypes || props.damageTypes || [];
	// Damage options from the insurance company
	const damageList = props.damageList || DAMAGE_TYPES || [];
	const check = buildUpdateStateFn(damageTypes, setInternalDamageTypes);

	const onClick = (): void => {
		props.onConfirm(props.areaId, damageTypes);
	};

	// If hiding, clear internal state
	useEffect(() => {
		if (!props.show) setInternalDamageTypes(null);

		// Verifies of click is outside of damage-window element and closes the modal.
		const handleOutsideClick = (ev: MouseEvent): void => {
			if (!windowRef?.current?.contains(ev.target)) props.close();
		};

		document.addEventListener('mousedown', handleOutsideClick);
		return (): void => document.removeEventListener('mousedown', handleOutsideClick);
	}, [props]);

	return (
		<div className={classes}>
			<div className="damage-window" ref={windowRef}>
				<div className="container">
					<h4>
						{getDamageArea(props.areaId)} ({props.areaId})
					</h4>
					<h5 className="subtitle">Seleccione daños</h5>
					<div className="radio-container">
						{damageList.map((damageType) => (
							<RoundButton
								key={damageType}
								checked={damageTypes.includes(damageType)}
								onClick={(): void => check(damageType)}
							>
								{damageType}
							</RoundButton>
						))}
					</div>
					<button className="sm" onClick={onClick}>
						Guardar
					</button>
				</div>
			</div>
		</div>
	);
};

export default DamageWindow;
