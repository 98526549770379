import localforage from 'localforage';
import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import * as Sentry from '@sentry/browser';
import { AppContext } from './interfaces';
import { parseStateToInspection, parseServerData } from './Utils';

const HELIOS_API = process.env.REACT_APP_HELIOS_API;

export const AXIOS_CONFIG = {
	headers: {
		Authorization: 'key_8fc02a15a05cfe383ff1bc6edbe7ee8466af88f1718de4c22d051d97761d4eba',
	},
};

export default class HeliosClient {
	constructor(private axios: AxiosStatic = Axios) {}

	async saveInspection(data: AppContext, id?: string): Promise<AxiosResponse | null> {
		const model = await parseStateToInspection({ ...data });
		try {
			return await this.axios.post(`${HELIOS_API}/inspections/save`, { ...model, id: id }, AXIOS_CONFIG);
		} catch (error) {
			console.log({
				model: JSON.stringify(model),
				error: JSON.stringify(error),
			});
			Sentry.captureException(error);
		}

		console.log('Response :: Save request is unsuccessfully.');

		return null;
	}

	async finishInspection(data: AppContext, id?: string): Promise<AxiosResponse | null> {
		const finish = true;
		const model = await parseStateToInspection({ ...data }, finish);
		try {
			return await this.axios.post(`${HELIOS_API}/inspections/finish`, { ...model, id: id }, AXIOS_CONFIG);
		} catch (error) {
			console.log({
				model: JSON.stringify(model),
				error: JSON.stringify(error),
			});
			Sentry.captureException(error);
		}

		console.log('Response :: Finish request is unsuccessfully.');

		return null;
	}

	async getInspection(id: string, context: AppContext): Promise<any> {
		const { data } = await this.axios.get(`${HELIOS_API}/inspections/${id}`, AXIOS_CONFIG);
		if (data && data.inspection && !data.inspection.id) data.inspection.id = await localforage.getItem('inspectionId');
		return await parseServerData(data, context);
	}

	async isActive(id: string): Promise<boolean> {
		const { data } = await this.axios.get(`${HELIOS_API}/inspections/${id}/virtual`, AXIOS_CONFIG);
		return data.data;
	}

	async uploadImage(serviceId: string, data: FormData): Promise<AxiosResponse> {
		return await this.axios.post(`${HELIOS_API}/services/files/uploadFile/${serviceId}`, data, AXIOS_CONFIG);
	}

	async deleteImage(fileUrl: string, serviceId: string): Promise<AxiosResponse> {
		return await this.axios.patch(`${HELIOS_API}/services/files/${serviceId}`, { fileUrl }, AXIOS_CONFIG);
	}
}
