import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import { Context } from '../../context/Context';
import { AppContext, Brands, LocationIndex } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import OptionData from '../../components/information/OptionData';
import HeliosClient from '../../context/Helios';
import { isVehicleGTM } from '../../shared/utils/gooogleTagManager';
import { BRAND, urlIdRewrite } from 'shared/utils';
import '../../assets/styles/screens/location/Location.scss';

interface Props {
	to: string;
	locationIndex: LocationIndex; // set location
	screenIndex: number;
}

const IsVehicleScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const location = useLocation();
	const { selectedIsVehicle } = ctx;
	const { id } = useParams<{ id: string }>();
	const [nextScreen, setNextScreen] = useState(urlIdRewrite(props.to, id));
	const canContinue = selectedIsVehicle != null;
	useEffect(() => {
		if (
			[Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS, Brands.ASSA, Brands.CONNECT].includes(BRAND as Brands) &&
			props.locationIndex === LocationIndex.IS_VEHICLE
		) {
			setNextScreen(!selectedIsVehicle ? '/' + id + '/policivePart' : urlIdRewrite(props.to, id));
		}
	}, [setNextScreen, props.locationIndex, props.to, selectedIsVehicle, id]);

	const handleConfirm = (): void => {
		isVehicleGTM(ctx, location.pathname);
		new HeliosClient().saveInspection(ctx, id);
	};

	return (
		<div className="location">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />

			{props.locationIndex === LocationIndex.IS_VEHICLE && (
				<>
					<Header
						title="¿El accidente fue contra un vehículo?"
						subtitle="De acuerdo a tu selección se habilitará la contraparte."
					/>
					<OptionData locationIndex={props.locationIndex} />
				</>
			)}

			{selectedIsVehicle === false && (
				<>
					<Header title="¿El incidente fue contra un objeto fijo?" />
					<OptionData locationIndex={LocationIndex.FIXED_OBJECT} />
				</>
			)}

			<ConfirmButton to={nextScreen} disabled={!canContinue} onClick={handleConfirm} />
		</div>
	);
};

export default IsVehicleScreen;
