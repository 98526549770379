import React, { Fragment, useContext } from 'react';
import { Context } from '../../context/Context';
import { AppContext, SelectDataLocation, ActionType, LocationIndex } from '../../context/interfaces';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import '../../assets/styles/components/location/SelectLocation.scss';
import clsx from 'clsx';

interface Props {
	locationIndex: LocationIndex; // set location
}
const OptionData = (props: Props): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const {
		dispatch,
		location,
		selectedAccidentType,
		selectedIsVehicle,
		selectedIsFixedObject,
		selectedIsDeposit,
		directDeposit,
	} = context;
	let currentDataToValidate = location.selectedLocation;
	let selectedData: SelectDataLocation[] = [
		{
			name: 'home',
			text: 'Casa',
			selected: false,
			disclaimer: 'Te solicitaremos la dirección exacta del lugar donde se hizo la inspección.',
		},
		{
			name: 'work',
			text: 'Trabajo',
			selected: false,
			disclaimer: 'Te solicitaremos la dirección exacta del lugar donde se hizo la inspección.',
		},
		{
			name: 'inspection',
			text: 'Lugar de Accidente',
			selected: false,
			disclaimer:
				'Le solicitaremos acceso a su ubicación actual, por favor habilite el GPS y brinde los servicios necesarios a la aplicación',
		},
		{
			name: 'mall',
			text: 'Centro Comercial',
			selected: false,
			disclaimer: 'Te solicitaremos la dirección exacta del lugar donde se hizo la inspección.',
		},
		{
			name: 'mechanic',
			text: 'Taller Mecánico',
			selected: false,
			disclaimer: 'Te solicitaremos la dirección exacta del lugar donde se hizo la inspección.',
		},
		{
			name: 'other',
			text: 'Otro',
			selected: false,
			disclaimer: 'Te solicitaremos la dirección exacta del lugar donde se hizo la inspección.',
		},
	];
	const selectedDataInspection: SelectDataLocation[] = [
		{
			name: 'default',
			text: 'Sí',
			selected: false,
			disclaimer:
				'Le solicitaremos acceso a su ubicación actual, por favor habilite el GPS y brinde los servicios necesarios a la aplicación',
		},
		{
			name: 'other',
			text: 'No',
			selected: false,
			disclaimer: 'Te solicitaremos la dirección exacta del lugar donde se hizo la inspección.',
		},
	];
	const selectedDataSketch: SelectDataLocation[] = [
		{ name: 'via', text: 'Sí', selected: false },
		{ name: 'other', text: 'No', selected: false },
	];
	const selectedDataIsVehicle: SelectDataLocation[] = [
		{ name: 'isVehicle', text: 'Sí', selected: false },
		{ name: 'other', text: 'No', selected: false },
	];
	const selectedDataIsFixedObject: SelectDataLocation[] = [
		{ name: 'isFixedObject', text: 'Sí', selected: false },
		{ name: 'other', text: 'No', selected: false },
	];
	const selectedDataIsDeposit: SelectDataLocation[] = [
		{ name: 'isDeposit', text: 'Sí', selected: false },
		{ name: 'none', text: 'No', selected: false },
	];
	const selectedDataDirectDeposit: SelectDataLocation[] = [
		{ name: 'cheque', text: 'Cheque', selected: false },
		{ name: 'ahorros', text: 'Ahorros', selected: false },
	];

	const changeLocation = (key: string) => {
		switch (props.locationIndex) {
			case LocationIndex.SELECT_LOCATION:
				dispatch({
					type: ActionType.SET_LOCATION,
					data: key,
				});

				if (key === 'inspection') {
					dispatch({
						type: ActionType.SET_COORDS,
						data: { lat: location.originalCoords?.lat, lng: location.originalCoords?.lng },
					});
					dispatch({ type: ActionType.SET_ADDRESS, data: location.originalAddress });
				}
				break;
			case LocationIndex.INSPECT_SITE:
				dispatch({
					type: ActionType.SET_LOCATION,
					data: key,
				});
				break;
			case LocationIndex.ACCIDENT_TYPE:
				dispatch({
					type: ActionType.SET_ACCIDENT_TYPE,
					data: key,
				});
				break;
			case LocationIndex.IS_VEHICLE:
				dispatch({
					type: ActionType.SET_IS_VEHICLE,
					data: key,
				});
				break;
			case LocationIndex.FIXED_OBJECT:
				dispatch({
					type: ActionType.SET_IS_FIXED_OBJECT,
					data: key,
				});
				break;
			case LocationIndex.IS_DEPOSIT:
				dispatch({
					type: ActionType.SET_IS_DEPOSIT,
					data: key,
				});
				break;
			case LocationIndex.DIRECT_DEPOSIT:
				dispatch({
					type: ActionType.SET_DEPOSIT_ACCOUNT_TYPE,
					data: key,
				});
				break;
			default:
				break;
		}
	};

	if (props.locationIndex !== LocationIndex.SELECT_LOCATION) {
		selectedData = [];
		switch (props.locationIndex) {
			case LocationIndex.INSPECT_SITE:
				selectedData = selectedDataInspection;
				break;
			case LocationIndex.ACCIDENT_TYPE:
				selectedData = selectedDataSketch;
				currentDataToValidate = selectedAccidentType ? selectedAccidentType : undefined;
				break;
			case LocationIndex.IS_VEHICLE:
				selectedData = selectedDataIsVehicle;
				currentDataToValidate = selectedIsVehicle === null ? '' : selectedIsVehicle ? 'isVehicle' : 'other';
				break;
			case LocationIndex.FIXED_OBJECT:
				selectedData = selectedDataIsFixedObject;
				currentDataToValidate = selectedIsFixedObject === null ? '' : selectedIsFixedObject ? 'isFixedObject' : 'other';
				break;
			case LocationIndex.IS_DEPOSIT:
				selectedData = selectedDataIsDeposit;
				currentDataToValidate = selectedIsDeposit === null ? '' : selectedIsDeposit ? 'isDeposit' : 'none';
				break;
			case LocationIndex.DIRECT_DEPOSIT:
				selectedData = selectedDataDirectDeposit;
				currentDataToValidate = directDeposit?.accountType ?? '';
				break;
			default:
				break;
		}
	}

	return (
		<div className="client-form">
			<div className="container">
				{selectedData.map((s, index) => {
					return (
						<Fragment key={index}>
							<div
								className={
									// eslint-disable-next-line @typescript-eslint/camelcase
									clsx('selectedArea', { selectedArea__active: s.name === currentDataToValidate })
								}
								onClick={() => changeLocation(s.name)}
							>
								{s.text}
							</div>

							{s.disclaimer && s.name === currentDataToValidate && (
								<div className="selectedArea__disclaimer">
									<InfoIcon className="selectedArea__disclaimerIcon" />
									<label className="selectedArea__disclaimerText">{s.disclaimer}</label>
								</div>
							)}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default OptionData;
