import React from 'react';
import { useHistory } from 'react-router';
import { Brands, BreadcrumbSteps } from '../context/interfaces';
import { backBtn, headerLogo } from '../context/Utils';
import WhatsAppButton from './common/WhatsAppButton';
import { BRAND, stepsByBrand } from 'shared/utils';
import '../assets/styles/components/Breadcrumbs.scss';

interface BreadcrumbsProps {
	currentStep: BreadcrumbSteps;
}

const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
	const { goBack } = useHistory();
	return (
		<>
			{BRAND === Brands.CSM && (
				<div className="header-logo">
					<img src={headerLogo} alt="header-logo" />
				</div>
			)}
			<div className="breadcrumbs-container">
				<div className="breadcrumbs">
					{props.currentStep !== 1 && <img src={backBtn} alt="back button" onClick={goBack} className="backChevron" />}
					<span data-testid="step-label">{stepsByBrand[props.currentStep]}</span>
					<WhatsAppButton />
				</div>
			</div>
		</>
	);
};

export default Breadcrumbs;
