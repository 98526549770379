import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext, DispatchFunction, IndividualStateKey } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import { insuranceAlertGTM } from '../../shared/utils/gooogleTagManager';
import '../../assets/styles/screens/insured/Narration.scss';
import { urlIdRewrite } from 'shared/utils';

const MIN_NARRATION_LENGTH = 10;

export const updateText = (
	event: React.ChangeEvent<HTMLTextAreaElement>,
	dispatch: DispatchFunction,
	stateKey: IndividualStateKey
): void => {
	dispatch({ type: ActionType.SET_ALERT_NARRATION_TEXT, data: { value: event.target.value, key: stateKey } });
};

interface Props {
	to: string;
	stateKey: IndividualStateKey;
	screenKey: number;
}

const InsuranceAlertScreen = (props: Props): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const location = useLocation();
	const { dispatch, insured } = context;
	const { id } = useParams<{ id: string }>();

	const setText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => updateText(event, dispatch, props.stateKey);
	const narrationData = insured.alertNarration ? insured.alertNarration : '';
	const canContinue = narrationData.trim().length > MIN_NARRATION_LENGTH;

	const handleConfirm = (): void => {
		insuranceAlertGTM(context, location.pathname);
		new HeliosClient().saveInspection(context, id);
	};

	return (
		<div className="narration-screen">
			<Breadcrumbs currentStep={props.screenKey} />
			<div className="container">
				<ProgressBar screenIndex={props.screenKey} />
				<Header title="¿Porqué no dió aviso inmediato a la aseguradora al momento del siniestro?" subtitle="" />
				<div className="body">
					<textarea placeholder="Describa los hechos" rows={5} value={narrationData} onChange={setText} />
					<ConfirmButton to={urlIdRewrite(props.to, id)} disabled={!canContinue} onClick={handleConfirm} />
				</div>
			</div>
		</div>
	);
};

export default InsuranceAlertScreen;
