import React, { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import MessageWindow from '../../components/common/MessageWindow';
import RoundButton from '../../components/RoundButton';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext, DispatchFunction, Responsible, Brands } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import { responsabilityScreenGTM } from '../../shared/utils/gooogleTagManager';
import { BRAND, urlIdRewrite } from 'shared/utils';

import '../../assets/styles/screens/summary/Responsibility.scss';

export const setResponsible = (responsible: Responsible, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_RESPONSIBLE, data: responsible });
};
export const setLegal = (bool: boolean, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_LEGAL_ASSISTANCE, data: bool });
};
// lets keep it for future implementations
export const setInitials = (event: React.ChangeEvent<HTMLInputElement>, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_INSURED_INITIALS, data: event.target.value });
};
interface Props {
	to: string;
	screenIndex: number;
}
const ResponsibilityScreen = (props: Props): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const location = useLocation();
	const { id } = useParams<{ id: string }>();
	const { dispatch, responsibility } = context;
	const { responsible, legal } = responsibility;
	const [showMessageWindow, setShowMessageWindow] = useState(false);

	const [messages, setMessage] = useState({
		title: '',
		message: '',
	});

	const showColillaWindow = (): void => {
		setMessage({
			title: 'Parte Policivo',
			message:
				'De no haber aceptación de responsabilidad del accidente, debe llamar a la Policía Nacional para reportarlo y esperar al oficial de tránsito que le entregará la Boleta de Tránsito respectiva. Debe tomar foto al final del proceso.',
		});
		setShowMessageWindow(true);
	};
	const showFUDWindow = (): void => {
		setMessage({
			title: 'Formato Único y Definitivo (FUD)',
			message:
				'Debe completar Formato Único y Definitivo (FUD) para tomar foto del mismo antes de finalizar el proceso.',
		});
		setShowMessageWindow(true);
	};

	const handleConfirm = (): void => {
		responsabilityScreenGTM(context, location.pathname);
		new HeliosClient().saveInspection(context, id);
	};

	const canContinue = responsible !== null && legal !== null;
	return (
		<div className="responsibility-screen">
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />
			<Header title="Aceptación de Responsabilidad" subtitle="¿Quién se va a hacer responsable de la colisión?" />
			<div className="container">
				<div className="responsible-options">
					<RoundButton
						checked={responsible === Responsible.COUNTERPART}
						onClick={(): void => {
							setResponsible(Responsible.COUNTERPART, dispatch);
							showFUDWindow();
						}}
					>
						La Contraparte
					</RoundButton>
					<RoundButton
						checked={responsible === Responsible.INSURED}
						onClick={(): void => {
							setResponsible(Responsible.INSURED, dispatch);
							if (BRAND !== Brands.SURA) showFUDWindow();
						}}
					>
						El Asegurado
					</RoundButton>
					<RoundButton
						checked={responsible === Responsible.NONE}
						onClick={(): void => {
							setResponsible(Responsible.NONE, dispatch);
							showColillaWindow();
						}}
					>
						Ninguno de los involucrados
					</RoundButton>
				</div>
				<p>¿Solicita asistencia legal?</p>
				<div className="legal-assistance-options">
					<RoundButton checked={!!legal} onClick={(): void => setLegal(true, dispatch)}>
						Sí
					</RoundButton>
					<RoundButton checked={legal !== null && !legal} onClick={(): void => setLegal(false, dispatch)}>
						No
					</RoundButton>
				</div>
			</div>
			<ConfirmButton to={urlIdRewrite(props.to, id)} disabled={!canContinue} onClick={handleConfirm} />
			{showMessageWindow && (
				<MessageWindow
					message={messages.message}
					title={messages.title}
					dismiss={(): void => setShowMessageWindow(false)}
				></MessageWindow>
			)}
		</div>
	);
};

export default ResponsibilityScreen;
