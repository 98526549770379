import { SketchProvider as SketchState } from '@connect-technology/connect-react-components-lib';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/styles/App.scss';
import AppState from './context/Context';
import ScreensRoot from './screens/Root';
import TagManager from 'react-gtm-module';
import './assets/styles/App.scss';

const tagManagerArgs = {
	gtmId: 'GTM-TLM4J3Q',
};

TagManager.initialize(tagManagerArgs);

const App = (): JSX.Element => {
	return (
		<div className="app">
			<AppState>
				<SketchState>
					<Router>
						<ScreensRoot />
					</Router>
				</SketchState>
			</AppState>
		</div>
	);
};

export default App;
