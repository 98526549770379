/* eslint-disable react/prop-types */
import React from 'react';
import { checkmarkOverview, logo } from '../../context/Utils';
import { Context } from '../../context/Context';
import { AppContext, Brands } from '../../context/interfaces';
import { BRAND } from 'shared/utils';
import { ReactComponent as CheckmarkSVG } from '../../assets/icons/checkmark_overview.svg';

import '../../assets/styles/screens/success/Success.scss';

const SuccessScreen = (): JSX.Element => {
	const { eventId } = React.useContext(Context) as AppContext;

	return (
		<div className="success-screen">
			<div className="container">
				<div className="icons">
					{Brands.CSM === BRAND && <img src={logo} alt="logo" className="icons__logo" />}
					{Brands.ASSA === BRAND.toLowerCase() ? (
						<CheckmarkSVG className="checkmark" />
					) : (
						<img src={checkmarkOverview} className="checkmark" alt="" />
					)}
				</div>
				<SuccessMessage eventId={eventId} />
			</div>
		</div>
	);
};

const SuccessMessage: React.FC<{ eventId: number | null }> = (props) => {
	switch (BRAND) {
		case Brands.CSM:
			return (
				<>
					<h1>¡Haz terminado tu inspección virtual!</h1>
					<p>Referencia:</p>
					<h1>#{props.eventId}</h1>
					<p>
						Estaremos revisando los datos, fotos y documentos incluidos en este proceso. Próximamente nos estaremos
						comunicando contigo.
					</p>

					<div className="contact-us">
						<p>
							Si necesitas información adicional, puedes llamar a TeleSeguros Múltiples al <br />
							<a href="tel:7877580101">787.758.0101</a>
						</p>
					</div>
				</>
			);
		case Brands.LAFISE:
			return (
				<>
					<h1>¡Excelente!</h1>
					<p>¡Su inspección ha sido completada!</p>
					<p>Número de expediente es:</p>
					<h1>#{props.eventId}</h1>
					<div className="contact-us">
						<p>Puedes continuar con tu viaje, te llegará un correo de Seguros LAFISE con los próximos pasos.</p>
						<br />
						<p>Para contactarnos durante horas laborales comuníquese:</p>
						<p>Teléfono: 800-523-4732 / 2246-2700</p>
					</div>
				</>
			);
		case Brands.OCEANICA:
			return (
				<>
					<h1>¡Excelente!</h1>
					<p>¡Su inspección ha sido completada!</p>
					<p>Número de expediente es:</p>
					<h1>#{props.eventId}</h1>
					<div className="contact-us">
						<p>Puedes continuar con tu viaje y te llegará un correo de Oceánica con los próximos pasos.</p>
						<br />
						<p>Para contactarnos durante horas laborales comuníquese:</p>
						<p>Teléfono: 800-289-4546 / 4102-7600</p>
					</div>
				</>
			);
		case Brands.ASSA:
			return (
				<>
					<h1>Su solicitud está siendo procesada</h1>
					<p>Número de expediente es:</p>
					<h1>#{props.eventId}</h1>
					<div className="contact-us">
						<p>
							Para más información sobre su reclamo, Contáctenos en horario de 8:00 am – 5:00 pm en nuestros diferentes
							canales de atención.
						</p>
						<p>Teléfono: 800-ASSA(2772)</p>
					</div>
				</>
			);
		default:
			return (
				<>
					<h1>Su solicitud está siendo procesada</h1>
					<p>Número de expediente es:</p>
					<h1>#{props.eventId}</h1>
					<div className="contact-us">
						<p>
							Para más información sobre su reclamo, Contáctenos en horario de 8:00 am – 5:00 pm en nuestros diferentes
							canales de atención.
						</p>
						<p>Teléfono: 800-7872 y WhatsApp: 6328-7872</p>
					</div>
				</>
			);
	}
};

export default SuccessScreen;
