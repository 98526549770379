import { ReactComponent as CheckMark } from 'assets/icons/checkmark.svg';
import { ReactComponent as ExclamationMark } from 'assets/icons/exclamation_mark.svg';
import { ReactComponent as RightChevron } from 'assets/icons/right-chevron.svg';
import PhotoGuide from 'assets/icons/photo-guide.svg';
import classNames from 'classnames';
import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmButton from 'components/common/ConfirmButton';
import Header from 'components/common/Header';
import ProgressBar from 'components/ProgressBar';
import { Context } from 'context/Context';
import { ActionType, AppContext, Brands, IndividualStateKey } from 'context/interfaces';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BRAND, sections, urlIdRewrite } from 'shared/utils';
import { photoSectionsScreenGTM } from 'shared/utils/gooogleTagManager';
import styles from './PhotoSectionsMenu.module.scss';
import { getPhotoSectionsStatus, hasRequiredPhotos } from './utils';

interface PhotoSectionsProps {
	to: string;
	screenIndex: number;
}

const PhotoSectionsMenu = ({ to, screenIndex }: PhotoSectionsProps): ReactElement => {
	const ctx = useContext(Context) as AppContext;
	const location = useLocation();
	const { id } = useParams<{ id: string }>();
	const { photos, photoSections, showInfoPhotos } = ctx;
	const [nextScreen, setNextScreen] = useState(urlIdRewrite(to, id));
	const galleryCompleted = hasRequiredPhotos(ctx);
	const [showWarningMessage, setShowWarningMessage] = useState(false);

	const currentStateKey = BRAND === Brands.CSM ? IndividualStateKey.INJURED : IndividualStateKey.INSURED;
	const stateKey = ctx[currentStateKey];

	const photoSectionStatus = getPhotoSectionsStatus(photos);

	const handleConfirm = (): void => {
		photoSectionsScreenGTM(ctx, location.pathname);
	};

	const closeInfo = (): void => {
		ctx.dispatch({ type: ActionType.SET_SHOW_INFO_PHOTOS, data: { value: false } });
	};

	const renderInfoIcons = (sectionId: string, required: boolean | undefined): ReactElement | undefined => {
		const completedSection = photoSectionStatus.completedSection[sectionId];
		if (photoSections[sectionId].visited && !completedSection && required) {
			return <ExclamationMark className={styles.exclamationMark} />;
		}
		if (photoSections[sectionId].visited && completedSection) {
			return <CheckMark className={styles.checkmark} />;
		}
	};

	const onVisitedSection = (sectionId: string): void => {
		ctx.dispatch({ type: ActionType.UPDATE_VISITED_SECTION, data: { sectionId, value: true } });
	};

	useEffect(() => {
		const shouldShowMessage = Object.entries(photoSections).some(([sectionId, { visited }]) => {
			const isCompletedSection = photoSectionStatus.completedSection[sectionId];
			const isSectionRequired = photoSectionStatus.requiredSection[sectionId];

			return visited && !isCompletedSection && isSectionRequired;
		});
		setShowWarningMessage(shouldShowMessage);
		// eslint-disable-next-line
	}, [photoSections]);

	useEffect(() => {
		if (BRAND === Brands.CSM)
			setNextScreen(!stateKey.fields.isOtherDriver ? '/' + id + '/injured/deposit' : urlIdRewrite(to, id));
	}, [setNextScreen, to, stateKey, id]);

	const className = classNames(styles['photoSection__button'], [styles[BRAND]]);

	const iconClassName = classNames(styles.icon, [styles[`${BRAND}__icon`]]);
	const chevronClassName = classNames(styles.icon, [styles[`${BRAND}__icon`], 'chevron']);

	const mainContainerClassName = classNames(styles.mainContainer, {
		[styles.csmBackground]: BRAND === Brands.CSM,
		[styles.assaBackground]: BRAND === Brands.ASSA,
	});

	return BRAND === Brands.CSM && showInfoPhotos ? (
		<div className={classNames(styles['photoSectionContainer'])}>
			<div className={classNames(styles['photoSectionInfo'])}>
				<p>¿Cómo tomar las fotografías?</p>
				<p>Sigue los siguientes consejos</p>
				<img src={PhotoGuide} alt="icon" />
				<p>
					<span>1)</span>Toma las fotografías colocando tu teléfono de forma horizontal.
				</p>
				<p>
					<span>2)</span>El lugar donde realizas la inspección debe ser un lugar abierto y contar con buena iluminación.
				</p>
			</div>
			<div>
				<ConfirmButton text="Continuar" to={'/' + id + '/photo/sections'} onClick={closeInfo} />
			</div>
		</div>
	) : (
		<div className={mainContainerClassName}>
			<Breadcrumbs currentStep={screenIndex} />
			<ProgressBar screenIndex={screenIndex} />
			<Header title="Seleccione el grupo de fotografías a tomar" />
			<div className={styles.container}>
				<div>
					{showWarningMessage && (
						<p className={styles.warning}>*Favor de llenar los encasillados requeridos para continuar</p>
					)}

					<div className={styles['photoSection__container']}>
						{sections[BRAND].map(({ srcImage, sectionId, description, required }, index) => {
							const Icon = srcImage;
							return (
								<Link
									key={index}
									to={{
										pathname: sectionId === 'video' ? '/' + id + '/camera/video' : '/' + id + '/photos/camera',
										state: { sectionId },
									}}
									className={className}
									onClick={(): void => onVisitedSection(sectionId)}
								>
									{renderInfoIcons(sectionId, required)}
									<Icon className={iconClassName} />
									{description}
									<RightChevron className={chevronClassName} />
								</Link>
							);
						})}
					</div>
				</div>
			</div>
			<ConfirmButton to={nextScreen} disabled={!galleryCompleted} onClick={handleConfirm} />
		</div>
	);
};

export default PhotoSectionsMenu;
