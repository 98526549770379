import TagManager from 'react-gtm-module';
import { AppContext } from '../../context/interfaces';
import { dateToLocaleString } from './dateUtils';
import { BRAND } from 'shared/utils';

const brand = BRAND?.toLocaleLowerCase() || '';

export const startVirtualInspectorGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};
	TagManager.dataLayer(tagManagerArgs);
};

export const clientInfoGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const locationGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const sceneScrenGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const sketchScrenGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const NarrationScreenGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const responsabilityScreenGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const damageScreenGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const photoSectionsScreenGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const policivePartGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const selectSketchGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const isVehicleGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const insuranceAlertGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

export const finishVirtualInspectorGTM = (context: AppContext, path: string): void => {
	const time = dateToLocaleString();
	const tagManagerArgs = {
		dataLayer: {
			...context,
			time,
			brand,
			event: path,
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};
