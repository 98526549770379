import { Photo, PhotoSections, PhotoSection, Validation } from '../../../context/interfaces';
import { ReactComponent as DamageIcon } from 'assets/icons/damage.svg';
import { ReactComponent as CarFrontSide } from 'assets/icons/car_front.svg';
import { ReactComponent as InternalPhoto } from 'assets/icons/internal-photo.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/ruc.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/i-360video.svg';
import {
	panoramic,
	carBack,
	damage,
	license,
	odometer,
	vin,
	plate,
	other,
	carPassengerSide,
	carDriverSide,
	carFront,
	label,
	driverLicense,
	internalPhoto,
} from '../../../context/Utils';
import { PhotoId } from '@connect-technology/connect-react-components-lib';

export const csmPhotosInjured: Photo[] = [
	{
		id: PhotoId.INJURED_LICENSE_PR,
		section: PhotoSection.INJURED_LICENSE,
		placeholder: driverLicense,
		selected: true,
		description: 'Licencia de Conducir',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.INJURED_VEHICLE_LICENSE_PR,
		section: PhotoSection.INJURED_VEHICLE_LICENSE,
		placeholder: license,
		selected: false,
		description: 'Licencia de Vehículo',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.INJURED_VEHICLE_MARBETE,
		section: PhotoSection.INJURED_MARBETE,
		placeholder: label,
		selected: false,
		description: 'Marbete',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.INJURED_VEHICLE_PLATE_PR,
		section: PhotoSection.INJURED_TABLILLA,
		placeholder: plate,
		selected: true,
		description: 'Tablilla',
		required: true,
		validations: [Validation.EXTRACT_PLATE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INJURED_VEHICLE_PASSENGER_SIDE,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carPassengerSide,
		selected: false,
		description: 'Lateral Derecha del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INJURED_VEHICLE_DRIVER_SIDE,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carDriverSide,
		selected: false,
		description: 'Lateral Izquierda del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INJURED_VEHICLE_BACK,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carBack,
		selected: false,
		description: 'Parte Trasera del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INJURED_VEHICLE_FRONT,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carFront,
		selected: false,
		description: 'Parte Delantera del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.INJURED_VEHICLE_DAMAGE_1,
		section: PhotoSection.INJURED_VEH_DAMAGE_PHOTOS,
		placeholder: damage,
		selected: true,
		description: 'Daños 1',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INJURED_VEHICLE_DAMAGE_2,
		section: PhotoSection.INJURED_VEH_DAMAGE_PHOTOS,
		placeholder: damage,
		selected: false,
		description: 'Daños 2',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INJURED_VEHICLE_DAMAGE_3,
		section: PhotoSection.INJURED_VEH_DAMAGE_PHOTOS,
		placeholder: damage,
		selected: false,
		description: 'Daños 3',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INJURED_VEHICLE_DAMAGE_4,
		section: PhotoSection.INJURED_VEH_DAMAGE_PHOTOS,
		placeholder: damage,
		selected: false,
		description: 'Daños 4',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'damage',
	},
	{
		id: PhotoId.INJURED_VEHICLE_INTERIOR_1,
		section: PhotoSection.INJURED_VEH_PANORAMIC,
		placeholder: internalPhoto,
		selected: true,
		description: 'Foto Interior 1',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicleInside',
	},
	{
		id: PhotoId.INJURED_VEHICLE_INTERIOR_2,
		section: PhotoSection.INJURED_VEH_PANORAMIC,
		placeholder: internalPhoto,
		selected: false,
		description: 'Foto Interior 2',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicleInside',
	},
	{
		id: PhotoId.INJURED_VEHICLE_ODOMETER,
		section: PhotoSection.INJURED_ODOMETER,
		placeholder: odometer,
		selected: false,
		description: 'Odómetro',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicleInside',
	},
	{
		id: PhotoId.INJURED_VEHICLE_VIN,
		section: PhotoSection.INJURED_VIN,
		placeholder: vin,
		selected: false,
		description: 'Chasis (VIN Number)',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicleInside',
	},
	{
		id: PhotoId.INJURED_VEHICLE,
		section: PhotoSection.INJURED_VEH_PANORAMIC,
		placeholder: panoramic,
		selected: false,
		description: 'Panorámica',
		required: true,
		validations: [],
		validationResults: {},
		sectionId: 'vehicle',
	},
	{
		id: PhotoId.OTHERS_1,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: true,
		description: 'Otros 1',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	},
	{
		id: PhotoId.OTHERS_2,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: false,
		description: 'Otros 2',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	},
	{
		id: 'vehicle_video',
		section: PhotoSection.VEHICLE_VIDEO,
		selected: false,
		description: 'Video',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'video',
	},
];

export const csmPhotoSections: PhotoSections[] = [
	{
		description: 'Exterior del Vehículo',
		srcImage: CarFrontSide,
		sectionId: 'vehicle',
		photosIds: [
			PhotoId.INJURED_VEHICLE_PLATE_PR,
			PhotoId.INJURED_VEHICLE_PASSENGER_SIDE,
			PhotoId.INJURED_VEHICLE_DRIVER_SIDE,
			PhotoId.INJURED_VEHICLE_BACK,
			PhotoId.INJURED_VEHICLE_FRONT,
			PhotoId.INJURED_VEHICLE,
		],
		required: true,
	},
	{
		description: 'Daños del Vehículo',
		srcImage: DamageIcon,
		sectionId: 'damage',
		photosIds: [
			PhotoId.INJURED_VEHICLE_DAMAGE_1,
			PhotoId.INJURED_VEHICLE_DAMAGE_2,
			PhotoId.INJURED_VEHICLE_DAMAGE_3,
			PhotoId.INJURED_VEHICLE_DAMAGE_4,
		],
		required: true,
	},
	{
		description: 'Interior del Vehículo',
		srcImage: InternalPhoto,
		sectionId: 'vehicleInside',
		photosIds: [
			PhotoId.INJURED_VEHICLE_INTERIOR_1,
			PhotoId.INJURED_VEHICLE_INTERIOR_2,
			PhotoId.INJURED_VEHICLE_ODOMETER,
			PhotoId.INJURED_VEHICLE_VIN,
		],
		required: true,
	},
	{
		description: 'Documentos',
		srcImage: DocumentIcon,
		sectionId: 'documents',
		photosIds: [PhotoId.INJURED_LICENSE_PR, PhotoId.INJURED_VEHICLE_LICENSE_PR, PhotoId.INJURED_VEHICLE_MARBETE],
		required: true,
	},
	{
		description: 'Otros',
		srcImage: PlusIcon,
		sectionId: 'others',
		photosIds: [PhotoId.OTHERS_1, PhotoId.OTHERS_2],
		required: false,
	},
	{
		description: 'Video',
		srcImage: VideoIcon,
		sectionId: 'video',
		photosIds: [],
		required: false,
	},
];
