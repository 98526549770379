import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { AppContext, IndividualStateKey, ScreenIndex, Brands, Responsible } from '../../context/interfaces';
import ConfirmButton from '../common/ConfirmButton';
import ProgressBar from '../ProgressBar';
import TextInput from '../ui/TextInput';
import { buildFields, Field, assignDataToForm } from './Utils';
import { clientInfoGTM } from '../../shared/utils/gooogleTagManager';
import { BRAND, urlIdRewrite } from 'shared/utils';

import '../../assets/styles/components/information/ClientForm.scss';
import DatePicker from 'components/common/DatePicker';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

interface Props {
	screenKey: ScreenIndex;
	title: string;
	to: string;
	isCounterpartInfo?: boolean;
}

// The next two functions attempt to capture the UX researched and noted here:
// https://ux.stackexchange.com/questions/74531/form-validation-when-should-error-messages-be-triggered
// https://alistapart.com/article/inline-validation-in-web-forms/

/**
 * Wrap around the fields.onChange, to add handling of UI validation state.
 * Particularly to eagerly validate the field if user corrected the value.
 */
export const buildOnChangeValidation = (field: Field, index: number, isValid: boolean[], setIsValid: any) => {
	return (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (field.onChange) {
			field.onChange(event);
		}
		// Only eagerly (during change) validate if user _corrected_ form.
		const previouslyValid = isValid[index];
		const newlyValid = field.isValid ? field.isValid(event.target.value) : true;
		if (!previouslyValid && newlyValid) {
			const newIsValid = [...isValid];
			newIsValid[index] = true;
			setIsValid(newIsValid);
		}
	};
};

export const buildOnChangeSelectedValidation = (field: Field, index: number, isValid: boolean[], setIsValid: any) => {
	return (event: React.ChangeEvent<HTMLSelectElement>) => {
		const inputData = {
			target: { value: event.currentTarget.value },
		} as React.ChangeEvent<HTMLInputElement>;

		if (field.onChange) {
			field.onChange(inputData);
		}

		// Only eagerly (during change) validate if user _corrected_ form.
		const previouslyValid = isValid[index];
		const newlyValid = field.isValid ? field.isValid(event.target.value) : true;
		if (!previouslyValid && newlyValid) {
			const newIsValid = [...isValid];
			newIsValid[index] = true;
			setIsValid(newIsValid);
		}

		return true;
	};
};

export const buildOnBlurValidation = (
	field: Field,
	index: number,
	value: string,
	isValid: boolean[],
	setIsValid: any,
	interactedWith: boolean[],
	setInteractedWith: any,
	stateKey: IndividualStateKey
): (() => void) => {
	return (): void => {
		let hasConterpart = false;
		hasConterpart =
			(BRAND === Brands.SURA || BRAND === Brands.LAFISE || BRAND === Brands.OCEANICA) &&
			stateKey === IndividualStateKey.COUNTERPART;
		if (hasConterpart && value && value.trim().length === 0) {
			const newIsValid = [...isValid];
			newIsValid[index] = field.required ? field.isValid(value) : true;
			setIsValid(newIsValid);
		} else {
			const newIsValid = [...isValid];
			newIsValid[index] = field.isValid ? field.isValid(value) : true;
			setIsValid(newIsValid);
		}
		const newInteractedWith = [...interactedWith];
		newInteractedWith[index] = true;
		setInteractedWith(newInteractedWith);
	};
};

const ClientForm = (props: Props): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const location = useLocation();
	const { id } = useParams<{ id: string }>();
	const { dispatch, eventId, responsibility } = context;

	const currentStateKey = props.isCounterpartInfo
		? IndividualStateKey.COUNTERPART
		: Brands.CSM === BRAND
		? IndividualStateKey.INJURED
		: IndividualStateKey.INSURED;

	const state = context[currentStateKey];
	const fields = buildFields(dispatch, currentStateKey, props.screenKey);
	const isSuraCounterpart = BRAND === Brands.SURA && currentStateKey === IndividualStateKey.COUNTERPART;
	const isNoneResponsible = responsibility?.responsible === Responsible.NONE;
	const initIsValid = (): boolean[] => {
		return fields.map((f) => {
			const valid = (!f.required && f.stateSelector(state)?.length === 0) || f.isValid(f.stateSelector(state));
			return f.readonly || valid;
		});
	};

	//  Because some fields might initially be invalid, but we dont want to show an error message
	//	too proactivly / just yet, we keep two pieces of state. Whether the user interacted with
	// 	component, and whether its valid. Condition to show error message is "already interacted"
	//	and "invalid". Condition to continue is all "valid".
	// 	These are boolean arrays, where index represents field, aligned to the 'fields' variable.
	const [interactedWith, setInteractedWith] = useState<boolean[]>(Array(fields.length).fill(false));
	const [isValid, setIsValid] = useState<boolean[]>(initIsValid()); // asume readonlys are valid
	const [redirectTo, setRedirectTo] = useState<string>(urlIdRewrite(props.to, id));

	useEffect(() => {
		if ((props.screenKey === ScreenIndex.INSURED || props.screenKey === ScreenIndex.INJURED) && state?.fields)
			setRedirectTo(!state.fields.isOtherDriver ? '/' + id + '/vehicle/information' : '/' + id + '/driver/information');
	}, [state, setRedirectTo, currentStateKey, props.screenKey, id]);

	if (eventId === null) {
		return (
			<div className="client-form">
				<h1 className="error">Evento no existe</h1>
			</div>
		);
	}

	const canContinue =
		isSuraCounterpart && isNoneResponsible ? isNoneResponsible : initIsValid().every((boolean) => boolean === true);

	const submitForm = async (): Promise<void> => {
		if (!state.fields.isOtherDriver && currentStateKey !== IndividualStateKey.COUNTERPART) {
			assignDataToForm(dispatch, ScreenIndex.DRIVER, state);
		}
		clientInfoGTM(context, location.pathname);
		await new HeliosClient().saveInspection(context, id);
	};

	return (
		<div className="client-form">
			{props.screenKey && <ProgressBar screenIndex={props.screenKey} />}
			<div className="fields-container">
				<div>
					{fields.map((field, index) => {
						const onChange = buildOnChangeValidation(field, index, isValid, setIsValid);
						const value = field.stateSelector(state);
						const onBlur = buildOnBlurValidation(
							field,
							index,
							value,
							isValid,
							setIsValid,
							interactedWith,
							setInteractedWith,
							currentStateKey
						);
						if (field.type === 'checkbox') {
							return (
								<label htmlFor={field.label} className="checkbox-form" key={field.label}>
									{field.label}

									<input
										id={field.label}
										type="checkbox"
										onChange={onChange}
										checked={value}
										className="checkbox-input"
									/>
									<span className="checkmark-input"></span>
								</label>
							);
						} else if (field.type === 'selected') {
							const onChangeSelected = buildOnChangeSelectedValidation(field, index, isValid, setIsValid);
							return (
								<div key={field.label} className="select-input">
									<FormControl variant="outlined" error={interactedWith[index] && !isValid[index]}>
										<InputLabel htmlFor="outlined-age-native-simple">{field.label}</InputLabel>
										<Select
											native
											value={value}
											onChange={(e) =>
												onChangeSelected({
													currentTarget: { type: 'selected', value: e.target.value },
													target: { type: 'selected', value: e.target.value },
												} as any)
											}
											onBlur={onBlur}
											label={field.label}
										>
											{field.selectedData &&
												field.selectedData.map((d) => {
													if (d.name) {
														return (
															<option key={d.id} value={d.id}>
																{d.name}
															</option>
														);
													} else {
														return <option key={d.id}></option>;
													}
												})}
										</Select>
										{interactedWith[index] && !isValid[index] && <FormHelperText>{field.errorMessage}</FormHelperText>}
									</FormControl>
								</div>
							);
						} else if (field.type === 'date') {
							return (
								<div className="date-input" key={index}>
									<DatePicker
										label={field.label}
										value={value || null}
										onChange={(v: any) => {
											onChange({ target: { type: 'date', value: v } } as any);
										}}
									/>
								</div>
							);
						} else {
							const errorMessage = isValid[index] || !interactedWith[index] ? undefined : field.errorMessage;
							return (
								<TextInput
									key={field.label}
									label={field.label}
									type={field.type}
									inputMode={field.inputMode}
									inputSize={field.inputSize}
									readonly={field.readonly}
									required={field.required}
									value={value}
									message={errorMessage}
									onChange={onChange}
									onBlur={onBlur}
								/>
							);
						}
					})}
				</div>
				<div>
					<ConfirmButton to={redirectTo} disabled={!canContinue} onClick={submitForm} />
				</div>
			</div>
		</div>
	);
};

export default ClientForm;
