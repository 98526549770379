import React, { useEffect, useRef } from 'react';
import { closeBtn } from '../../context/Utils';
import '../../assets/styles/components/common/MessageWindow.scss';

interface MessageWindowProps {
	dismiss: () => void;
	title?: string;
	message?: string;
	children?: JSX.Element;
	hideConfirmButton?: boolean;
	hideCloseButton?: boolean;
}

const MessageWindow = (props: MessageWindowProps): JSX.Element => {
	const windowRef: any = useRef(null);

	useEffect(() => {
		// Verifies of click is outside of damage-window element and closes the modal.
		const handleOutsideClick = (ev: MouseEvent): void => {
			if (!windowRef.current.contains(ev.target)) props.dismiss();
		};

		document.addEventListener('mousedown', handleOutsideClick);
		return (): void => document.removeEventListener('mousedown', handleOutsideClick);
	}, [props]);

	return (
		<div className="message-window-container">
			<div className="message-window" ref={windowRef}>
				<div className="container">
					{!props.hideCloseButton && (
						<img className="close" id="closeBtn" src={closeBtn} alt="Close button" onClick={props.dismiss} />
					)}
					{!props.children && (
						<div className="default">
							<div className="body">
								{props.title && <h4>{props.title}</h4>}
								<p>{props.message}</p>
							</div>
						</div>
					)}
					{props.children}
					{!props.hideConfirmButton && (
						<div className="buttons-container">
							<button className="sm" onClick={props.dismiss}>
								Confirmar
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MessageWindow;
