import { Photo, Brands, PhotoSection } from '../../context/interfaces';
// import { defaultPhotos } from './photos/default';
import { suraPhotos } from './photos/sura';
import { csmPhotosInjured } from './photos/csm';
import { lafisePhotos } from './photos/lafise';
import { oceanicaPhotos } from './photos/oceanica';
import { qualitasPhotos } from './photos/qualitas';
import { addIcon, other } from '../../context/Utils';
import { assaPhotos } from './photos/assa';

export const getPhotoCollection = (brand: string): Photo[] => {
	switch (brand) {
		case Brands.SURA:
			return addPhotoTemplate(suraPhotos, Brands.SURA, true);
		case Brands.LAFISE:
			return addPhotoTemplate(lafisePhotos, Brands.LAFISE, true);
		case Brands.ASSA:
			return addPhotoTemplate(assaPhotos, Brands.ASSA, true);
		case Brands.OCEANICA:
			return addPhotoTemplate(oceanicaPhotos, Brands.OCEANICA, true);
		case Brands.CSM:
			return addPhotoTemplate(csmPhotosInjured, Brands.CSM, true);
		case Brands.QUALITAS:
			return addPhotoTemplate(qualitasPhotos, Brands.QUALITAS, true);
		default:
			return addPhotoTemplate(lafisePhotos, Brands.CONNECT, true);
	}
};

export const initPhotos = (photos: Photo[]): Photo[] => {
	return photos.length > 0 ? photos : getPhotoCollection(process.env.REACT_APP_BRAND || '');
};

export const addPhotoTemplate = (photos: Photo[], brand: string, isTemplate?: boolean): Photo[] => {
	const timeStampId = Math.round(new Date().getTime() / 1000);
	const id = isTemplate ? 'add_gallery' : 'gallery_' + timeStampId;
	const currentDescription = isTemplate ? 'Tomar Nueva Foto...' : 'Otra Foto_' + timeStampId;
	const currentIcon = isTemplate ? addIcon : other;
	let currentSection = PhotoSection.OTHERS;
	const sectionIdFirstIndex = 'vehicle_video';

	if (brand === Brands.CSM) {
		currentSection = PhotoSection.INJURED_OTHERS_DOCS;
	}
	const photoTemplate = {
		id: id,
		section: currentSection,
		placeholder: currentIcon,
		selected: false,
		description: currentDescription,
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'others',
	};

	// This is the initial photo list from the Context
	let photoIniIndex = photos.findIndex((p) => p.id === sectionIdFirstIndex);
	let initialSegment = photos.slice(0, photoIniIndex + 1);

	if (isTemplate) return [...initialSegment, photoTemplate];
	// This process is execute from Photo Section (Add new Photo  from gallery)
	const photoFiniIndex = photos.findIndex((p) => p.id === 'add_gallery');
	const finalSegment = photos.slice(photoFiniIndex, photos.length);
	photoIniIndex = photoFiniIndex - 1;
	initialSegment = photos.slice(0, photoIniIndex + 1); // Positioning after each new photos
	return [...initialSegment, photoTemplate, ...finalSegment];
};
