import React, { ReactElement, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import csmIcon from 'assets/icons/csm.svg';
import RulesText from './RulesText';
import HeliosClient from 'context/Helios';
import { Context } from 'context/Context';
import { AppContext } from 'context/interfaces';
import styles from './RightsForm.module.scss';
import classNames from 'classnames';
import { BRAND, urlIdRewrite } from 'shared/utils';

interface RightsFormProps {
	to: string;
}

const RightsForm = (props: RightsFormProps): ReactElement => {
	const context = useContext(Context) as AppContext;
	const { id } = useParams<{ id: string }>();
	const [language, setLanguage] = useState('ES');

	const handleChangeLanguage = (): void => {
		if (language === 'ES') {
			return setLanguage('EN');
		}
		setLanguage('ES');
	};

	const handleClick = (): void => {
		new HeliosClient().saveInspection(context, id);
	};

	const className = classNames(styles.button, [styles[BRAND]]);

	return (
		<div className={styles.container}>
			<div className={styles.logo}>
				<div className={styles.imagesContainer}>
					<img src={csmIcon} alt="logo" />
					<img src={RulesText()[language].flag} className={styles.flag} onClick={handleChangeLanguage} alt="flag" />
				</div>
			</div>
			{RulesText()[language].textBody}
			<Link to={urlIdRewrite(props.to, id)} className={className} onClick={handleClick}>
				{language === 'ES' ? 'Acepto' : 'I Accept'}
			</Link>
		</div>
	);
};

export default RightsForm;
